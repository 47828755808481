@use 'assets/styles/utils/helper' as h;

/* stylelint-disable at-rule-no-unknown  */
/* stylelint-disable scss/at-rule-no-unknown  */
/* stylelint-disable property-no-unknown  */

.omega_videoContainer {
  aspect-ratio: 16 / 9;
  container-name: video-container;
  container-type: size;
}

body .omega_tapToUnmuteBtn {
  margin: 10px 0 0 10px;
}

.omega_volume-muteBtn {
  padding-top: 5px;
}

.omega_volume {
  padding-right: 8px;
}

@media only screen and (max-width: 1365px) {
  :fullscreen > .omega_tapToUnmuteBtn,
  :-webkit-full-screen > .omega_tapToUnmuteBtn {
    display: none;
  }
}

body.today .omega_tapToUnmuteBtn {
  background-color: #ffb186;

  .omega_tapToUnmuteBtn-icon {
    color: #102039;
  }
}

body.news .omega_tapToUnmuteBtn {
  background-color: #3061ff;

  .omega_tapToUnmuteBtn-icon {
    color: #fff;
  }
}

body.msnbc .omega_tapToUnmuteBtn {
  background-color: #ffbe03;

  .omega_tapToUnmuteBtn-icon {
    color: #000;
  }
}

body.noticias .omega_tapToUnmuteBtn {
  background-color: #c31010;

  .omega_tapToUnmuteBtn-icon {
    color: #fff;
  }
}

.video-loading-animation {
  z-index: 51;
}

.omega_aspectRatioContainer {
  width: 100%;
  aspect-ratio: 16 / 9;

  .sticky-pip__close {
    z-index: h.get-z-index('sticky-video-parent');
  }
}

.omega-container {
  background: #000;
  height: 100%;
  left: 0;
  max-height: 100vh;
  position: relative;
  width: 100%;
  top: 0;
  z-index: 3;
}

@container video-container (max-width: 312px) {
  .omega_icon-live-with-dot,
  .omega_btnSubtitle,
  .omega_playPauseMobile,
  .omega_iconLiveWithDot {
    display: none;
  }

  .omega_playPauseDesktop {
    display: block;
    padding-right: 1.5rem;
  }

  .omega_controlRack {
    padding-bottom: 6px;
  }

  .omega_volume-container {
    display: block;
  }

  .omega_subtitles {
    display: none;
  }
}

@container video-container (max-width: 220px) {
  .omega_iconLiveWithDot {
    display: block;
  }
}

@container video-container (max-width: 480px) {
  .omega_volume-container {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  .omega_volume-container {
    display: none;
  }
}
