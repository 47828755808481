@use 'assets/styles/utils/helper' as h;

.playPauseMobile {
  justify-self: center;
  align-self: center;
  font-size: 36px;
  border: none;
  background: none;
  color: var(--white);
  z-index: var(--z-index-control-rack);

  span {
    display: block;
  }

  @include h.breakpoint-m {
    display: none;
  }
}

.playPauseDesktop {
  @include h.breakpoint-s-only {
    display: none;
  }
}
