@use 'assets/styles/utils/helper' as h;

.controlRack {
  display: flex;
  align-self: end;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 16px;
  z-index: var(--z-index-control-rack);
  overflow: hidden;
  color: #fff;

  @include h.breakpoint-m {
    padding: 0 24px 13px;
  }

  button {
    border: none;
    background: none;
    color: #ebebeb;

    &:hover {
      color: #fff;
    }
  }
}

@include h.breakpoint-x {
  .controlRack {
    :global(.smorgasbord__column3) & {
      padding: 0 10px 20px 5px;

      .left {
        column-gap: var(--space-8);
      }

      .btnFullScreen {
        padding: 0 0 0 0.75rem;
      }
    }
  }
}

.controlRack.uiVisible {
  opacity: 1;
}

.controlRack:not(.uiVisible) {
  transition: var(--transition-backdrop-fade);
  opacity: 0;
}

.sections {
  align-items: center;
  flex-grow: 1;
  flex-basis: 0;
}

.left {
  column-gap: var(--space-20);
  display: flex;
  justify-content: flex-start;
}

.center {
  display: flex;
  justify-content: center;
}

.right {
  display: flex;
  justify-content: flex-end;

  .btnFullScreen {
    padding: 0 0 0 h.rem(24px);
  }
}

.playPauseMobileContainer.uiVisible {
  opacity: 1;
}

.playPauseMobileContainer:not(.uiVisible) {
  transition: var(--transition-backdrop-fade);
  opacity: 0;
}
