@use 'assets/styles/utils/helper' as h;

.subtitles {
  position: absolute;
  background: var(--black);
  color: var(--white);
  bottom: 15%;
  display: flex;
  justify-self: center;
  padding: 0.5em 1em;
  font-family: var(--founders-mono, sans-serif);
}
