@use 'assets/styles/utils/helper' as h;

.volumeContainer {
  display: none;
  column-gap: 6px;

  @include h.breakpoint-m {
    display: flex;
  }
}

.volumeContainerOnNoticias {
  display: flex;
}

.muteToggle {
  display: block;
  height: 24px;
  width: 24px;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: var(--white);
  flex-shrink: 0;
}

.volumeSliderContainer {
  display: flex;
  justify-content: flex-end;
  width: 0;
  overflow: hidden;
  transition: width 0.05s 0.5s ease-in-out;
}

.volumeContainer:hover .volumeSliderContainer {
  width: 88px;
  transition-delay: 0s;
}

// using separate pseudo element selectors per browser since `:is()` doesn't work for pseudo
// elements, and using a comma separated list would break for unsupported selectors. following
// styling guide from CSS Tricks here:
// https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/

@mixin sliderThumb {
  background: var(--white);
  height: 13px;
  width: 13px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

@mixin sliderTrack {
  background-image:
    linear-gradient(
      to right,
      var(--white) 0% var(--volume-percentage),
      rgba(217, 217, 217, 0.5) var(--volume-percentage) 100%
    );
  height: 5px;
}

.volumeSlider {
  --volume-percentage: var(--volume, 0%);
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  width: 100%;
  cursor: pointer;
  flex-shrink: 0;

  // safari/chrome/edge
  &::-webkit-slider-runnable-track {
    @include sliderTrack;
  }

  &::-webkit-slider-thumb {
    @include sliderThumb;
    -webkit-appearance: none;
    appearance: none;
    margin-top: -4px;
  }

  // firefox
  &::-moz-range-track {
    @include sliderTrack;
  }

  &::-moz-range-thumb {
    @include sliderThumb;
    border: 0;
  }

  &::-moz-focus-outer {
    border: 0;
  }
}

.speakerQuiet path:last-child {
  opacity: 0;
}
