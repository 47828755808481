/* stylelint-disable ramen/import-helper-file */
// disabling rule since we're not using helper stuff/tokens in this file
@use 'sass:math';
@use 'assets/styles/utils/helper' as h;

.aspectRatioContainer {
  --z-index-video: 0;
  --z-index-captions: 10;
  --z-index-control-rack-backdrop: 20;
  --z-index-control-rack: 30;
  --z-index-pip-close: 30;
  --z-index-tap-to-unmute: 40;
  --transition-backdrop-fade: opacity .667s ease-in-out;
  position: relative;
  overflow-y: hidden;

  &.placeholder {
    padding-bottom: #{math.div(9, 16) * 100%};
  }

  :global(.sticky-pip__close) {
    z-index: var(--z-index-pip-close);
  }
}

.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  @include h.breakpoint-x {
    :global(.smorgasbord__column3) & {
      top: -15px;
    }
  }
}

.videoContainer:not(.isUIVisible),
.videoContainer:not(.isUIVisible) button {
  cursor: none;
}
