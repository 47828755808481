@use 'assets/styles/utils/helper' as h;

.controlRackBackdrop {
  z-index: var(--z-index-control-rack-backdrop);
  align-self: stretch;
  justify-self: stretch;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
  opacity: 1;

  @include h.breakpoint-m {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9) 100%);
  }
}

// TODO: pip mode will implement the gradient like this (similar to mobile)
// .controlRackGradient.pipMode {
//   background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%);
// }

.controlRackBackdrop.uiVisible {
  opacity: 1;
}

.controlRackBackdrop:not(.uiVisible) {
  transition: var(--transition-backdrop-fade);
  opacity: 0;
}
