@use 'assets/styles/utils/helper' as h;

.coreVideoSlate {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.coreVideoSlate img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
