.liveLabel {
  display: block;
  position: relative;

  &::before {
    content: '';
    top: 3px;
    left: -7px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: absolute;
    background-color: #fff;
  }
}
