/* stylelint-disable ramen/import-helper-file */
// disabling rule since we're not using helper stuff/tokens in this file

.coreVideoContainer iframe {
  height: 100%;
  width: 100%;
  display: block;
  pointer-events: none;
  z-index: var(--z-index-video);
}
