/* stylelint-disable ramen/import-helper-file */

.captionOn {
  border-radius: 2px;
  background-color: var(--white);

  path {
    fill: #050910;
  }
}

.captionOff {
  border-radius: 2px;

  path {
    fill: var(--white);
  }
}
